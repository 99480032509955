<template>
  <div>
    <v-card class="mx-auto" max-width="1000">
      <v-img class="white--text align-end" src="/img/banner.jpg">
        <v-card-title>{{ $t('securityInformation.banner') }}</v-card-title>
      </v-img>

      <v-card-text class="text--primary" style="padding-bottom:0px;">
        <h3>{{ $t('securityInformation.banner') }}</h3>
        <div v-html=" $t('securityInformation.content')"></div>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          rounded
          @click="$router.push('/information/')"
        >{{ $t('actions.goback') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'SecurityVetting',
  data: () => ({
    dialog: false,
    gdpr: false
  }),
  computed: {},
  methods: {}
}
</script>
